<template>
  <div
    class="day-switcher fixed px-2 py-3 lg:p-5 z-40 transition-opacity duration-200 cursor-pointer font-meijer-goth-md text-red"
    :class="{ 'show': show }"
    @mouseenter="show = true"
    @mouseleave="show = false"
  >
    <select
      v-model="day"
      class="cursor-pointer focus:outline-none"
      @change="onSelect"
    >
      <option
        v-for="(number, index) in dayRange(12, 5)"
        :key="`day-${number}`"
        :selected="number === day"
        :value="index + 1"
      >
        DEC {{ number }}
      </option>
    </select>
  </div>
</template>

<script>
import { bus } from "@/consts";
export default {
  name: "DaySwitcher",
  data() {
    return {
      show: true,
      day: 1
    };
  },
  methods: {
    dayRange(length, start) {
      return Array.from(new Array(length), (value, index) => index + start);
    },
    onSelect(event) {
      bus.$emit("changeCurrentDay", event.target.value);
    }
  }
};
</script>
<style lang="scss">
.day-switcher {
  bottom: 0;
  left: 0;

  .hover-enabled & {
    bottom: auto;
    left: auto;
    opacity: 0;
    right: 0;
    top: 0;

    &.show {
      opacity: 1;
    }
  }
}
</style>
