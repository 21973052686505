<template>
  <div class="day-detail-wrapper relative overflow-hidden">
    <Snowfall />
    <div
      class="day-detail flex items-center z-10 relative overflow-hidden pt-16 sm:pt-0 md:pt-24"
      :class="{ 'opacity-0': !dayContent }"
    >
      <div class="sm:container flex flex-col sm:relative pt-3 md:pt-4 w-full">
        <main
          ref="main"
          class="detail-container grid md:grid-cols-12 relative md:pt-0"
        >
          <div class="detail-content flex flex-col order-2 pt-4 col-span-12 md:order-1 md:col-span-5 md:pt-0">
            <transition
              name="fade-up"
              mode="out-in"
              appear
            >
              <span
                :key="`number-${number}`"
                class="day relative font-meijer-goth-md text-white text-center uppercase md:text-left pb-6 md:pt-10 z-10"
              >December {{ dayInt }} | DAY {{ number }}</span>
            </transition>
            <transition
              name="fade-up"
              mode="out-in"
              appear
            >
              <h1
                :key="`title-${number}`"
                class="title detail-header font-playfair text-center md:text-left font-bold italic lg:mt-0 leading-none relative"
              >
                {{ title }}
              </h1>
            </transition>
            <transition
              name="fade-up"
              mode="out-in"
              appear
            >
              <prismic-rich-text
                v-if="dayContent"
                :key="`description-${number}`"
                :field="dayContent.description"
                class="description font-meijer-goth-bk text-lg text-grey-dark leading-loose lg:text-black z-10"
              />
            </transition>
            <div class="day-detail-nav-wrap order-4 md:order-1 pt-3 pb-10">
              <div class="flex flex-col justify-center items-center relative">
                <DayDetailNav
                  type="desktop"
                  :content="dayContent"
                  :days="days"
                  :number-int="numberInt"
                  :day-int="dayInt"
                  :title="title"
                />
              </div>
            </div>
          </div>
          <section class="col-span-12 md:col-start-7 md:col-span-6 flex flex-col lg:z-10 order-1 md:order-2 md:justify-center">
            <transition
              name="fade"
              mode="out-in"
              appear
            >
              <div
                v-if="hasDetailImage && screenWidth >= 640"
                :key="`day-img-${number}`"
                class="day-img-wrap relative lg:z-10"
              >
                <prismic-image
                  v-if="dayContent"
                  :field="detailImage"
                  class="day-img desktop block object-cover h-full w-full"
                  @load="onDayImageLoad"
                />
                <img
                  class="illustration desktop"
                  src="/images/illustration-desktop-gradient.png"
                  alt=""
                >
              </div>
            </transition>

            <!-- dummy, invisible mobile img to start load -->
            <LazyImage
              v-if="hasDetailImage && screenWidth < 640"
              :key="`dummy-mobile-day-img-${number}`"
              class="absolute w-0 h-0 opacity-0 pointer-events-none"
              :src="mobileDayImgSrc"
              alt=""
              @imgLoaded="onMobileImgLoaded"
            />
            <!-- visible mobile image to animate in after load is complete -->
            <transition
              name="fade-down"
              mode="out-in"
              appear
            >
              <img
                v-if="hasDetailImage && screenWidth < 640"
                :key="mobileDayImgSrc"
                class="illustration mobile duration-300"
                :class="mobileImgLoaded ? 'opacity-100' : 'opacity-0'"
                :src="mobileDayImgSrc"
                alt=""
              >
            </transition>
          </section>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import DayDetailNav from "@/components/DayDetailNav";
import Snowfall from "@/components/Snowfall";
import LazyImage from "@/components/LazyImage"
import { checkDeepValue } from "@/utils";

export default {
  name: "DayDetail",
  components: { DayDetailNav, Snowfall, LazyImage },
  props: {
    days: Array,
    content: Object,
    daysActive: Number,
    homeContent: Object,
    screenWidth: Number,
    hoverEnabled: Boolean,
    animateIntro: Boolean,
  },
  data() {
    return {
      dayImgLoaded: false,
      mobileImgLoaded: false
    };
  },
  computed: {
    number() {
      return this.$route.params.number;
    },
    mobileDayImgSrc() {
      return `/images/day-detail-images/day${this.number}.png`;
    },
    tagDay() {
      const date = checkDeepValue(this.dayContent, "date", "");
      return date ? date.split("-").slice(-1)[0] : "";
    },
    dayContent() {
      const content = this.days[this.number - 1];
      return content ? content : null;
    },
    title() {
      return checkDeepValue(this.dayContent, "title.0.text", "");
    },
    description() {
      return checkDeepValue(this.dayContent, "description.0.text", "");
    },
    numberInt() {
      return parseInt(this.number);
    },
    dayInt() {
      return parseInt(this.tagDay);
    },
    detailImage() {
      return checkDeepValue(this.dayContent, "detail_image");
    },
    hasDetailImage() {
      if (!this.detailImage) return;
      return Object.keys(this.detailImage).length;
    },
    metaImage() {
      return `${process.env.VUE_APP_SITE_URL}/days/images/day${this.number}.jpg`;
    }
  },
  watch: {
    days(updatedDays) {
      if (updatedDays.length > 0) {
        // now that days have been fetched, check if the content for this
        // day exists (has been published via Prismic) and if not, redirect
        if (!this.dayContent) {
          this.$router.push("/");
        }
      }
    },
    mobileDayImgSrc: {
      handler() {
        this.mobileImgLoaded = false;
      },
      immediate: true,
    },
    $route(to, from) {
      if (to.path !== from.path) {
        // since the DayDetail component is reused for all days routes, we need
        // to manually reset product image data & scroll triggers on route change
        this.productImagesLoaded = 0;
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    // prevent navigation to non-existent days
    const dayNumberInt = parseInt(to.params.number);
    if (typeof dayNumberInt !== "number") {
      next(false);
    } else {
      dayNumberInt < 1 || dayNumberInt > 12 ? next(false) : next();
    }
  },
  methods: {
    onDayImageLoad() {
      this.dayImgLoaded = true;
    },
    onMobileImgLoaded() {
      this.mobileImgLoaded = true;
    }
  },
  metaInfo() {
    const title = `12 days of gifting | ${this.title} | meijer`;
    return {
      title: title,
      meta: [
        { name: "description", content: this.description },
        { property: "og:title", content: title },
        { property: "og:description", content: this.description },
        { property: "og:site_name", content: "The 12 Days of Meijer Gifting" },
        {
          property: "og:url",
          content: `${process.env.VUE_APP_SITE_URL}/days/day${this.number}.html`
        },
        { property: "og:image", content: this.metaImage },
        { property: "twitter:card", content: "summary_large_image" },
        { property: "twitter:site", content: "@meijer" },
        { property: "twitter:title", content: title },
        { property: "twitter:description", content: this.description },
        { property: "twitter:image", content: this.metaImage }
      ]
    };
  }
};
</script>

<style lang="scss">
.day-detail-wrapper {
  background: url('/images/background-day-detail-sm.jpg');
  background-size: cover;

  @media (min-width: theme("screens.md")) {
    background: url('/images/background-day-detail.jpg');
    background-size: cover;
  }
}

.day-detail {
  // subtract the height of the container in the footer component
  min-height : calc(100vh - 74px);

  @media (max-width: theme("screens.md")) {
    overflow-y: hidden;
  }

  .detail-container {
    padding-top  : 40vw;
    margin-left  : 25px;
    margin-right : 25px;
    max-width    : none;

    @media (min-width: 480px) {
      padding-top: 10vw;
      margin-left  : 40px;
      margin-right : 40px;
    }

    @media (min-width: theme("screens.sm")) {
      margin-left : 0;
      margin-right : 0;
      padding-top: 50px;
    }

    @media (min-width: theme("screens.md")) {
      margin-top: 0;
      padding-top: 0;
    }
  }

  .detail-content {
    @media (max-width: theme("screens.sm")) {
      padding-top: 140px;
    }

    @media (max-width: theme("screens.sm")) and (min-width: 480px) {
      padding-top: 60vw;
    }
  }

  .day {
    position: relative;
    letter-spacing: .15em;
    order: 2;

    @media (min-width: theme("screens.md")) {
      order: unset;
    }
  }

  .detail-header {
    font-weight: bold;
    font-size: 66px;
    color: #ffffff;
    text-shadow: 0 64px 50px rgba(0, 0, 0, 0.3),
      0 27px 22px rgba(0, 0, 0, 0.2),
      0 14px 11px rgba(0, 0, 0, 0.12),
      0 4px 3px rgba(0, 0, 0, 0.1),
      0 2px 15px rgba(0, 0, 0, 0.25);
    will-change: opacity, transform;

    @media (min-width: theme("screens.md")) {
      text-shadow: 0 64px 51px rgba(0, 0, 0, 0.2),
        0 27px 22px rgba(0, 0, 0, 0.14),
        0 14px 12px rgba(0, 0, 0, 0.12),
        0 8px 6px rgba(0, 0, 0, 0.1),
        0 4px 3px rgba(0, 0, 0, 0.08),
        0 2px 2px rgba(0, 0, 0, 0.05);
    }

    @media (min-width: theme("screens.lg")) {
      font-size: 75px;
    }

    @media (min-width: theme("screens.xl")) {
      font-size: 90px;
    }

    @media (min-width: theme("screens.xxl")) {
      font-size: 100px;
    }
  }

  .title {
    padding-bottom: 35px;
    z-index: 1;

    @media (min-width: theme("screens.sm")) {
      margin-top: -75px;
    }

    @media (min-width: theme("screens.md")) {
      margin-top: 0;
      padding-bottom: 20px;
    }

    @media (min-width: theme("screens.lg")) {
      padding-bottom: 35px;
    }
  }

  .description {
    position: relative;
    color: white;

    &.fade-up-enter-active {
      transition-delay: 450ms;
    }

    @media (max-width: theme("screens.md")) {
      order: 3;
      text-align: center;
      max-width: 500px;
      margin: 0 auto;
    }

    @media (min-width: theme("screens.xl")) {
      min-height: 100px;
    }
  }

  .day-img-wrap {
    display: block;
    position: relative;

    @media (min-width: theme("screens.sm")) {
      margin: 0 12%;
      padding-top: 78%;
    }

    @media (min-width: theme("screens.md")) {
      margin: -100px 0 0 0;
      padding-top: 100%;
      transform: scale(1.1);
    }

    &.fade-up-enter-active {
      transition-delay: 450ms;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;

      @media (min-width: theme("screens.sm")) {
        border-radius: 50%;
      }
    }

    .illustration {
      max-width: none;
    }

    .illustration.desktop {
      left: -1.5%;
      top: 1.35%;
      width: 101.5%;
      height: 100%;
    }

    .detail-nav-wrap {
      @media (max-width: theme("screens.md")) {
        order         : 4;
        text-align    : center;
        margin-bottom : 45px;
      }
    }
  }

  .illustration.mobile {
    max-width: none;
    height: auto;
    width: calc(100vw + 52px); // full viewport width + extra to account for margins
    position: absolute;
    top: -36vw;
    left: -26px; // move left to account for margin

    @media (min-width: 480px) {
      left: -42px; // move left to account for margin
      width: calc(100vw + 82px); // full viewport width + extra to account for margins
    }
  }
}
</style>
