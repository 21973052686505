<template>
  <!-- eslint-disable -->
  <svg
    width="11"
    height="21"
    viewBox="0 0 11 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.315 3.68559C9.69165 3.5695 8.84979 3.48277 8.32033 3.48277C6.88667 3.48277 6.79354 4.06323 6.79354 4.99197V6.64526H10.3773L10.0649 10.07H6.79354V20.4869H2.30631V10.07H0L0 6.64526H2.30631V4.52692C2.30631 1.62529 3.77077 0 7.44768 0C8.72513 0 9.66012 0.174139 10.8752 0.406324L10.315 3.68559Z"
      fill="white"
    />
  </svg>
  <!-- eslint-enable -->
</template>

<script>
import Vector from "@/mixins/Vector";
export default {
  name: "Facebook",
  mixins: [Vector]
};
</script>