import Vue from 'vue'

export const colors = {
   black: '#404040',
   white: '#FFFFFF',
   grey: {
      light: '#D6D6D6',
      default: '#C6C6C6',
      dark: '#7D7D7D',
      darker: '#9C9C9C'
   },
   red: {
      default: '#B23D1E',
      dark: '#8D2E15'
   },
   blue: '#5883AB',
   gold: {
      light: '#D9CB99',
      default: '#B19E58',
      dark: '#AD9D64'
   }
}



// set up separate Vue instance to use as an event bus
export const bus = new Vue()