<template>
  <div class="magic-wrap h-full absolute">
    <img
      class="magic magic-1 max-w-none"
      src="/images/snow-1.png"
      alt=""
    >
    <img
      class="magic magic-2 absolute top-0 right-0 max-w-none"
      src="/images/snow-2.png"
      alt=""
    >
  </div>
</template>

<script>
export default {
  name: "Magic",
  props: {
    active: Boolean
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss">
.magic-wrap,
.magic {
  height: 400px;

  @media (min-width: 400px) {
    height: 600px;
  }
}

.magic {
  animation-duration: 9s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @media (min-width: theme("screens.lg")) {
    animation-duration: 9s;
  }

  &-1 {
    animation-name: magic1;
  }

  &-2 {
    animation-name: magic2;
  }
}

@keyframes magic1 {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }

  3% {
    opacity: 1;
  }

  97% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateX(150px);
  }
}

@keyframes magic2 {
  0% {
    opacity: 0;
    transform: translateX(0);
  }

  3% {
    opacity: 1;
  }

  97% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateX(200px);
  }
}
</style>
