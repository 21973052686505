<template>
  <header class="site-header pt-4 pb-5 md:pt-12 absolute top-0 z-20 w-full">
    <div class="container flex justify-between items-center">
      <transition name="fade">
        <prismic-link
          v-if="content.hasOwnProperty('header_logo_link')"
          :field="content.header_logo_link"
          @click="sendTracking"
        >
          <Logo
            class="logo-main"
            :fill-hex="'#D43D44'"
            :branded="true"
          />
        </prismic-link>
      </transition>
      <transition name="fade">
        <div
          v-if="shareText"
          class="header-social flex lg:flex-col items-center text-white"
        >
          <span class="share-copy pr-4 font-meijer-goth-md uppercase tracking-widest text-md lg:pr-0 lg:pt-2 lg:order-2 lg:font-meijer-goth-md lg:uppercase">{{ shareText }}</span>
          <SocialShare location="header" />
        </div>
      </transition>
    </div>
  </header>
</template>

<script>
import Logo from "@/components/svg/Logo";
import SocialShare from "@/components/SocialShare";
import { colors } from "@/consts";
import { checkDeepValue } from "@/utils";
import { gtag } from 'ga-gtag';

export default {
  name: "Header",
  components: { Logo, SocialShare },
  props: {
    content: Object
  },
  data() {
    return {};
  },
  computed: {
    shareText() {
      return checkDeepValue(this.content, "share_text.0.text", "");
    }
  },
  created() {
    this.colors = colors;
  },
  methods: {
    sendTracking() {
      gtag('event', 'link_click', {
        'meijer_logo': 'header',
      });
    }
  }
};
</script>

<style lang="scss">
.site-header {

  @media (max-width: theme("screens.md")) {
    box-shadow: none;

    .container {
      max-width: none;
    }
  }
}

.logo-main {
  @media (min-width: theme("screens.xl")) {
    width: 135px;
    height: 52px;
  }

  @media (max-width: theme("screens.md")) {
    width: 103px;
    height: 40px;
  }
}
</style>
