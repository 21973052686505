<template>
  <div class="home flex flex-col h-full min-h-screen overflow-hidden">
    <Snowfall />
    <Hero
      :content="content"
      :animate-intro="animateIntro"
      :screen-width="screenWidth"
    />
    <main class=" flex flex-col flex-grow">
      <Calendar
        v-if="days.length"
        :days="days"
        :days-active="daysActive"
        :animate-intro="animateIntro"
        :hover-enabled="hoverEnabled"
        :screen-width="screenWidth"
      />
    </main>
  </div>
</template>

<script>
import Hero from "@/components/Hero";
import Calendar from "@/components/Calendar";
import Snowfall from "@/components/Snowfall";
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { bus } from "@/consts";

export default {
  name: "Home",
  components: {
    Hero,
    Calendar,
    Snowfall
  },
  props: {
    days: Array,
    daysActive: Number,
    content: Object,
    animateIntro: Boolean,
    screenWidth: Number,
    hoverEnabled: Boolean
  },
  created() {
    gsap.registerPlugin(ScrollToPlugin);
    bus.$on("goToDetailPage", this.onGoToDetailPage);
  },
  methods: {
    onGoToDetailPage(number) {
      gsap.to(this.$el, 0.4, {
        opacity: 0,
        ease: "power1",
        onComplete: () => {
          const path = `/day/${number}`;
          if (this.$route.path !== path) {
            this.$router.push(path);
          }
        }
      });
    }
  }
};
</script>

<style lang="scss">
</style>
