<template>
  <div
    class="day-nav flex flex-col justify-center items-center w-full"
    :class="type"
  >
    <prismic-link
      v-if="hasCollectionLink"
      :field="collectionLink"
      class="collection-cta button relative overflow-hidden text-white py-3 px-8 my-6 md:mt-6"
      @click="sendTracking"
    >
      <span class="uppercase relative z-10">See the collection</span>
    </prismic-link>

    <div
      class="nav-buttons flex items-center justify-between text-white pt-6 sm:pt-4 md:pt-0 xl:pt-1 md:pb-20 items-stretch w-full"
      :class="{'prev-disabled': content && numberInt < 2}"
    >
      <router-link
        :to="prevLink"
        class="button prev overflow-hidden relative md:px-4 lg:px-3 xl:px-5 lg:w-full"
      >
        <Arrow
          direction="left"
          :fill-hex="colors.red.default"
          class="relative z-10 xl:mr-4"
        />
        <span class="hidden lg:inline-flex relative z-10">{{ prevLinkText }}</span>
      </router-link>

      <router-link
        to="/"
        class="grid-button button md:mx-3 xl:mx-6 md:mt-0 md:top-auto md:left-auto flex justify-center items-center overflow-hidden relative flex-shrink-0"
        :class="{'prev-disabled': content && numberInt < 2}"
      >
        <Home
          class="relative z-10"
        />
      </router-link>

      <router-link
        :to="nextLink"
        :event="nextDayActive ? 'click' : ''"
        :class="nextLinkClasses"
        class="button next overflow-hidden relative md:relative md:px-4 lg:px-3 xl:px-5 lg:w-full"
      >
        <span class="hidden lg:inline-flex relative z-10">{{ nextLinkText }}</span>
        <Arrow
          direction="right"
          :fill-hex="colors.red.default"
          class="relative z-10 xl:ml-4"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
import Home from "@/components/svg/Home";
import Arrow from "@/components/svg/Arrow";
import { colors } from "@/consts";
import { checkDeepValue, leadZeros } from "@/utils";
import { gtag } from 'ga-gtag';

export default {
  name: "DayDetailNav",
  components: { Home, Arrow },
  props: {
    content: Object,
    days: Array,
    numberInt: Number,
    dayInt: Number,
    type: String,
    title: String
  },
  data() {
    return {
      colors: colors
    };
  },
  computed: {
    getTrackingLabel() {
      // Get our title, convert it to snake_case for our tracking label
      return this.title.toLowerCase().replaceAll(' ', '_')
    },
    nextDayActive() {
      return typeof this.days[this.numberInt] !== "undefined";
    },
    nextLink() {
      return `/day/${this.numberInt + 1}`;
    },
    nextLinkText() {
      return `December ${leadZeros(this.dayInt + 1)}`;
    },
    prevLink() {
      return `/day/${this.numberInt - 1}`;
    },
    prevLinkText() {
      return `December ${leadZeros(this.dayInt - 1)}`;
    },
    prevLinkClasses() {
      return [
        this.nextDayActive ? "cursor-pointer" : "cursor-not-allowed",
        this.content && this.numberInt < 2
          ? "opacity-100"
          : "opacity-0 pointer-events-none"
      ];
    },
    nextLinkClasses() {
      return [
        this.nextDayActive ? "cursor-pointer" : "cursor-not-allowed",
        this.content && this.numberInt < 12
          ? "opacity-100"
          : "opacity-0 pointer-events-none"
      ];
    },
    collectionLink() {
      return checkDeepValue(this.content, "collection_link");
    },
    hasCollectionLink() {
      if (!this.collectionLink) return;
      return Object.keys(this.collectionLink).length;
    },
  },
  methods: {
    sendTracking () {
      gtag('event', 'link_click', {
        'see_collection': `${this.getTrackingLabel}`,
      });
    }
  }
}
</script>

<style lang="scss">
.day-nav {
  max-width: 350px;

  @media (min-width: theme("screens.md")) {
    max-width: none;
  }

  .nav-buttons {
    align-items: center;

    &.prev-disabled {
      .prev {
        opacity: 0;
        pointer-events: none;
      }
      @media (min-width: theme("screens.lg")) {
        justify-content : flex-start;

        .prev {
          display: none;
        }

        .grid-button {
          margin-right: 20px;
        }

        .next {
          min-width: 150px;
          width: auto;
        }
      }
    }
  }

  .prev,
  .next,
  .collection-cta,
  .grid-button {
    border-radius: 999px;
    font-size: 14px;
    transition: opacity 0.2s ease-out, background-color 0.25s ease-out;

    span {
      transition: background-color 0.25s ease-out;
    }

    path {
      transition: fill 0.25s ease-out;
    }
  }

  .collection-cta,
  .grid-button {
    @media (any-hover: hover) {
      &:hover {
        background-color: white;

        span {
          color:  theme("colors.red.default");
        }

        path {
          fill :  theme("colors.red.default");
        }
      }
    }
  }

  .prev,
  .next {
    background-color: white;
    border-radius: 999px;
    justify-content: space-between;
    padding-top: 12px;
    padding-bottom: 12px;

    @media (max-width: theme("screens.md")) {
      width: 36px;
      height: 36px;
      justify-content: center;
    }

    @media (any-hover: hover) {
      &:hover {
        background-color: theme("colors.red.default");
        span {
          color: white;
        }

        path {
          fill : white;
        }
      }
    }

    &.hidden {
       opacity: 0;
       pointer-events: none;
       display: none;
    }

    &.cursor-not-allowed {
      background-color: theme("colors.grey.default");
    }

    span {
      color: theme("colors.red.default");
    }

    @media (min-width: theme("screens.lg")) {
      top: auto;
    }
  }

  .grid-button {
    &.prev-disabled {
      margin-left: 0;
    }
    svg path {
      fill : theme("colors.white");
    }
  }

  .grid-button {
    background-color: theme("colors.red.default");
    height: 41px;
    width: 41px;
    border-radius: 50%;

    @media (max-width: theme("screens.md")) {
      height: 66px;
      width: 66px;

      svg {
        height: 26px;
        width: 26px;
      }
    }
  }

  .collection-cta {
    background-color: theme("colors.red.default");
    min-width: 200px;

    @media (min-width: theme("screens.sm")) {
      min-width: 300px;
    }

    @media (min-width: theme("screens.md")) {
      width: 100%;
    }
  }

  &.mobile {
    .collection-cta {
      order: 2;
    }

    .prev,
    .next {
      background: none;
      width: 100%;

      svg {
        height: 24px;
        width: 15px;
      }

      path {
        fill: theme("colors.red.default");
      }
    }

    .prev {
      left: -0.5rem;
    }

    .next {
      right: -0.5rem;
    }

    .grid-button {
      background: none;

      rect {
        fill: theme("colors.red.default");
      }
    }

    @media (min-width: theme("screens.lg")) {
      display: none;
    }
  }

  &.desktop {
    @media (min-width: theme("screens.lg")) {
      .collection-cta {
        // font-size: 1.35rem;
        padding: 12px 40px;
      }
    }
  }
}
</style>
