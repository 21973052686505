<template>
  <img
    ref="img"
    alt=""
    @load="handleLoad"
  >
</template>

<script>

export default {
  name: 'LazyImage',
  props: {
    imgSrc: String,
    imgSrcMd: String,
    imgSrcLg: String,
    screenWidth: Number
  },
  data () {
    return {
    }
  },
  watch : {
    screenWidth (newWidth, oldWidth) {
      if (newWidth !== oldWidth) {
        this.setImgSrc()
      }
    }
  },
  mounted () {
    setTimeout(() => {
      this.setImgSrc()
    }, 100)
  },
  methods: {
    handleLoad () {
      this.$emit('imgLoaded')
    },
    setImgSrc () {
      let responsiveSrc = this.imgSrc
      if (this.imgSrcLg && this.screenWidth > 2000) {
        responsiveSrc = this.imgSrcLg
      } else if (this.imgSrcMd && this.screenWidth >= 640) {
        responsiveSrc = this.imgSrcMd
      }
      if (this.$refs.img && responsiveSrc) {
        this.$refs.img.src = responsiveSrc
      }
    }
  }
}
</script>
