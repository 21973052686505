<template>
  <div class="social-share flex items-center">
    <a
      :href="twitterShareUri"
      target="_blank"
      class="px-2 lg:px-3 link-hover hover:opacity-75"
      @click="handleTwitterClick"
    >
      <Twitter :fill-hex="colors.red.default" />
    </a>
    <button
      class="pl-2 lg:px-3 pr-1 link-hover hover:opacity-75"
      @click="fbShare"
    >
      <Facebook :fill-hex="colors.red.default" />
    </button>
  </div>
</template>

<script>
import Twitter from '@/components/svg/Twitter'
import Facebook from '@/components/svg/Facebook'
import { colors } from '@/consts'
import { checkDeepValue } from '@/utils'
import { gtag } from 'ga-gtag';

export default {
  name: 'SocialShare',
  components: { Twitter, Facebook },
  props: {
    location: String
  },
  data () {
    return {
      colors: colors
    }
  },
  computed: {
    shareUrl () {
      const dayNumber = checkDeepValue(this.$route, 'params.number')
      const params = dayNumber ? `/days/day${dayNumber}.html` : ''
      return `${process.env.VUE_APP_SITE_URL}${params}`
    },
    shareTitle () {
      return encodeURIComponent('The 12 Days of Meijer Gifting')
    },
    twitterShareUri () {
      const url = encodeURIComponent(this.shareUrl)
      return `http://twitter.com/share?text=${this.shareTitle}&url=${url}`
    }
  },
  methods: {
    fbShare () {
      gtag('event', 'button_click', {
        'social_share': 'facebook',
      });

      if (!window.FB) return
      window.FB.ui({
        method: 'share',
        href: this.shareUrl,
        display: 'popup'
      })
    },
    handleTwitterClick () {
      gtag('event', 'button_click', {
        'social_share': 'twitter',
      });
    }
  }
}
</script>

<style lang="scss">
.social-share {
  a {
    .signup & {
      @media (max-width : theme('screens.lg')) {
        @apply px-5;
      }
    }
  }
}
</style>
