<template>
  <div
    id="app"
    class="relative"
    :class="{ 'hover-enabled': hoverEnabled }"
  >
    <Header :content="homeContent" />
    <DaySwitcher v-if="daySwitcherActive" />
    <router-view
      :days="activeDays"
      :days-active="daysActive"
      :content="homeContent"
      :animate-intro="animateIntro"
      :screen-width="screenWidth"
      :hover-enabled="hoverEnabled"
    />
    <Footer :content="homeContent" />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import DaySwitcher from "@/components/DaySwitcher";

import { checkDeepValue } from "@/utils";
import { bus } from "@/consts";
import { install } from 'ga-gtag';

export default {
  name: "App",
  components: {
    Header,
    Footer,
    DaySwitcher
  },
  data() {
    return {
      days: [],
      homeContent: {},
      daysActive: 12,
      hoverEnabled: false,
      animateIntro: true,
      screenWidth: 0,
      resizeTimer: null,
      campaignActive: false
    };
  },
  computed: {
    activeDays() {
      return this.days.filter((day, idx) => idx < this.daysActive);
    },
    daySwitcherActive() {
      return (
        process.env.NODE_ENV === "development" ||
        process.env.VUE_APP_ENV === "staging"
      );
    }
  },

  created() {
    install('G-ST0BY1MRGX');
    this.fetchHomeContent();
    this.fetchDays();
    this.checkForHover();
    this.setScreenWidth();
    this.loadPinItScript();
    bus.$on("changeCurrentDay", this.onChangeCurrentDay);
    window.addEventListener("resize", this.onResize);
  },
  methods: {
    fetchHomeContent() {
      this.$prismic.client.getSingle("home").then(document => {
        const content = checkDeepValue(document, "data");
        this.homeContent = content;
      });
    },

    fetchDays() {
      this.$prismic.client
        .query(this.$prismic.Predicates.at("document.type", "day"), {
          orderings: "[my.day.date]"
        })
        .then(response => {
          const results = checkDeepValue(response, "results");
          const days = results.map(result => {
            return result.data;
          });
          this.days = days;
        });
    },
    loadPinItScript() {
      this.$loadScript("//assets.pinterest.com/js/pinit.js");
    },

    onChangeCurrentDay(dayNumber) {
      this.daysActive = parseInt(dayNumber);
    },

    checkForHover() {
      if (window.matchMedia("(hover: hover)").matches) {
        this.hoverEnabled = true;
      }
    },
    onResize() {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(() => {
        this.setScreenWidth();
        this.checkForHover();
      }, 250);
    },
    setScreenWidth() {
      this.screenWidth = window.innerWidth;
    }
  }
};
</script>

<style lang="scss">
@import "./styles/index";

body {
  background: url('/images/whitebg-repeat.jpg');
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}
</style>
