<template>
  <div class="hero relative bg-top font-meijer-goth-bk overflow-hidden">
    <transition
      name="fade-long"
      appear
    >
      <div class="bg absolute top-0 left-0 h-full w-full">
        <LazyImage
          class="sky max-w-none relative"
          img-src="/images/sky-sm.jpg"
          img-src-md="/images/sky-md.jpg"
          :screen-width="screenWidth"
        />
      </div>
    </transition>
    <transition
      name="fade-up"
      appear
    >
      <div class="clouds absolute h-full w-full">
        <LazyImage
          class="cloud cloud-lg absolute"
          img-src="/images/cloud-lg.png"
        />
        <LazyImage
          class="cloud cloud-sm absolute"
          img-src="/images/cloud-sm.png"
        />
      </div>
    </transition>
    <div class="container">
      <div class="hero-content flex flex-col justify-center items-center z-10">
        <div class="hero-animation-wrap relative w-full mt-12 z-10">
          <HeroAnimation :screen-width="screenWidth" />
        </div>
      </div>
      <div class="hero-content flex flex-col justify-center items-center relative z-10">
        <prismic-rich-text
          v-if="content.hasOwnProperty('hero_copy')"
          :class="copyClasses"
          class="hero-copy text-center text-white text-sm md:text-base -mt-5 sm:-mt-10 md:-mt-12 xl:-mt-20 mb-4"
          :field="content.hero_copy"
        />
      </div>
    </div>
  </div>
</template>

<script>
import HeroAnimation from "@/components/HeroAnimation"
import LazyImage from "@/components/LazyImage"
import { colors } from "@/consts";

export default {
  name: "Hero",
  components: { HeroAnimation, LazyImage },
  props: {
    content: Object,
    screenWidth: Number
  },
  data() {
    return {
      colors: colors,
      showCopy: false
    };
  },
  computed: {
    copyClasses() {
      return [
        this.showCopy ? "opacity-100" : "opacity-0"
      ];
    }
  },
  mounted() {
    setTimeout(() => {
      this.showCopy = true;
    }, 1300);
  }
};
</script>

<style lang="scss">
.hero {
  margin-bottom: 20vw;
  padding-bottom: 15vw;

  @media (min-width: theme("screens.md")) {
    margin-bottom : 0;
    padding-bottom: 200px;
  }

  p {
    color: theme("colors.darkBlue");
  }

  @media (min-width: 2500px) {
    background-size: 100% auto;
  }
}

.sky {
  left: 50%;
  transform: translateX(-50%);
  width: 800px;

  @media (min-width: theme("screens.sm")) {
    width: 2500px;
  }

  @media (min-width: 2400px) {
    width: 100%;
  }
}

.hero-animation-wrap {
  max-width: 90%;

  @media (min-width: 400px) {
    max-width: 385px;
  }

  @media (min-width: theme("screens.md")) {
    max-width: 485px;
  }

  @media (min-width: theme("screens.lg")) {
    max-width: 530px;
  }

  @media (min-width: theme("screens.xl")) {
    max-width: 750px;
  }
}

.snowflakes-placeholder {
  max-width: none;
  transition-delay: 1s;
  transition-duration: 0.7s;

  @media (max-width: theme("screens.sm")) {
    left: -35%;
    width: 180%;
  }

  @media (min-width: theme("screens.sm")) and (max-width: theme("screens.md")) {
    left: -20%;
    width: 150%;
  }
}

.clouds {
  transition-delay: 1.25s;
  transition-duration: 0.5s;

  .cloud-lg {
    left: 0;
    top: 25%;
    transform: translateX(-50%);
    width: 32vw;

    @media (min-width: theme("screens.sm")) {
      top: 25%;
      transform: translateX(-28%);
    }

    @media (min-width: theme("screens.md")) {
      top: 30%;
    }

    @media (min-width: theme("screens.lg")) {
      top: 25%;
    }

    @media (min-width: theme("screens.xl")) {
      top: 29%;
      transform: translateX(-20%);
    }
  }

  .cloud-sm {
    right: 0;
    top: 34%;
    transform: translateX(30%);
    width: 40vw;

    @media (min-width: theme("screens.sm")) {
      top: 36%;
      transform: translateX(30%);
      width: 20vw
    }

    @media (min-width: theme("screens.md")) {
      top: 45%;
    }

    @media (min-width: theme("screens.xl")) {
      top: 48%;
    }
  }
}

.hero-copy {
  max-width: 300px;
  transition: opacity 700ms ease-out;

  @media (min-width: 400px) and (max-width: theme("screens.md")) {
    margin-top: -2rem;
  }

  @media (min-width: theme("screens.md")) {
    max-width: 420px;
  }
}
</style>
