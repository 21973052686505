<template>
  <footer class="site-footer z-10 bg-red text-white relative font-meijer-goth-md">
    <div class="flex flex-wrap items-center leading-tight">
      <div class="container flex items-center justify-between ">
        <transition name="fade">
          <prismic-link
            v-if="content.hasOwnProperty('footer_logo_link')"
            class=" absolute absolute-center lg:left-50 link-hover hover:opacity-75"
            :field="content.footer_logo_link"
            @click="sendTracking"
          >
            <Logo
              class="logo-mobile w-24"
              :fill-hex="colors.white"
            />
          </prismic-link>
        </transition>
        <div class="policy text-base md:text-lg lg:px-0 py-3">
          <prismic-link
            v-if="content.hasOwnProperty('privacy_page_link')"
            class="text-white link-hover hover:opacity-75"
            :field="content.privacy_page_link"
          >
            {{ privacyPageTitle }}
          </prismic-link>
          <span> • </span>
          <prismic-link
            v-if="content.hasOwnProperty('terms_page_link')"
            class="text-white link-hover hover:opacity-75"
            :field="content.terms_page_link"
          >
            {{ termsPageTitle }}
          </prismic-link>
        </div>

        <div
          class="back-to-top flex items-center justify-end text-right cursor-pointer link-hover leading-none hover:opacity-75"
          @click="backToTop"
        >
          <prismic-rich-text
            v-if="content.hasOwnProperty('back_to_top')"
            class="mr-2 w-10 sm:w-auto sm:flex-shrink-0 text-base md:text-lg"
            :field="content.back_to_top"
          />
          <CircleUp
            class="flex-shrink-0"
            :fill-hex="colors.white"
          />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import Logo from "@/components/svg/Logo";
import CircleUp from "@/components/svg/CircleUp";
import { colors } from "@/consts";
import { checkDeepValue } from "@/utils";
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { gtag } from 'ga-gtag';

export default {
  name: "Footer",
  components: { Logo, CircleUp },
  props: {
    content: Object
  },
  data() {
    return {};
  },
  computed: {
    privacyPageTitle() {
      return checkDeepValue(this.content, "privacy_page_title.0.text", "");
    },
    termsPageTitle() {
      return checkDeepValue(this.content, "terms_page_title.0.text", "");
    }
  },
  created() {
    this.colors = colors;
    gsap.registerPlugin(ScrollToPlugin);
  },
  methods: {
    backToTop() {
      gsap.to(window, {
        scrollTo: { duration: 1, y: 0, ease: "power2" }
      });
    },
    sendTracking() {
      gtag('event', 'link_click', {
        'meijer_logo': 'footer'
      });
    }
  }
};
</script>

<style lang="scss">

.site-footer .container {
  height: 74px;
}

.logo-mobile {
  @media (max-width: theme("screens.sm")) {
    height: 30px;
    width: 80px;
  }
}

.policy {
  max-width: 100px;

  @media (max-width: 320px) {
    font-size: 11px;
  }

  @media (min-width: theme("screens.md")) {
    max-width: 120px;
  }

  @media (min-width: theme("screens.lg")) {
    max-width: 300px;
  }
}

.back-to-top {
  width: 80px;

  @media (max-width: 320px) {
    font-size: 11px;
  }

  @media (min-width: theme("screens.md")) {
    width: 100px;
  }

  @media (min-width: theme("screens.lg")) {
    width: 300px;
  }

  @media (min-width: theme("screens.xl")) {
    width: 362px;
  }
}
</style>
