<template>
  <div class="snowfall-wrapper relative">
    <div
      id="snowfall"
      ref="snowfall"
      class="overflow-hidden"
    />
  </div>
</template>

<script>

export default {
  name: "Snowfall",
  data() {
    return {
      snowFlakesCount: 200,
      baseCSS: undefined
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    randomInt(value = 100) {
      return Math.floor(Math.random() * value) + 1;
    },
    randomRange(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    addCSS(rule) {
      // Append style for each snowflake to the head
      let css = document.createElement('style');
      css.type = 'text/css';
      css.appendChild(document.createTextNode(rule)); // Support for the rest
      document.getElementsByTagName("head")[0].appendChild(css);
    },
    spawnSnowCSS (snowFlakesCount){
      // Create style for snowflake
      let snowflake_name = "snowflake";
      let rule = ``;
      if (typeof this.baseCSS !== 'undefined') {
        rule = this.baseCSS;
      }

      for (let i = 1; i < snowFlakesCount; i++) {
        let random_x = Math.random() * 100; // vw
        // Perhaps we need to create a limit for the range here to avoid snowflakes being thrown off screen
        let random_offset = this.randomRange(-100000, 100000) * 0.0001; // vw offset;
        let random_x_end = random_x + random_offset;
        let random_x_end_yoyo = random_x + (random_offset / 2);
        let random_yoyo_time = this.randomRange(30000, 80000) / 100000;
        let random_yoyo_y = random_yoyo_time * 100; // vh
        let random_scale = Math.random();
        let fall_duration = this.randomRange(30, 70) * 1; // s
        let fall_delay = this.randomInt(30) * -1; // s
        let opacity_ = Math.random();

        // console.log('random offset', random_offset)
        // console.log('random x end', random_x_end)

        rule += `
        .${snowflake_name}:nth-child(${i}) {
            opacity: ${opacity_};
            transform: translate(${random_x}vw, -10px) scale(${random_scale});
            animation: fall-${i} ${fall_duration}s ${fall_delay}s linear infinite;
        }

        @keyframes fall-${i} {
            ${random_yoyo_time * 100}% {
                transform: translate(${random_x_end}vw, ${random_yoyo_y}vh) scale(${random_scale});
            }

            to {
                transform: translate(${random_x_end_yoyo}vw, 100vh) scale(${random_scale});
            }

        }
        `
      }

      this.addCSS(rule);
    },
    spawnSnow (snowFlakesCount) {
      // Creating snowflakes
      this.snowFlakesCount -= 1;

      for (let x = 0; x < snowFlakesCount; x++) {
        let board = document.createElement('div');
        board.className = "snowflake";

        this.$refs.snowfall.appendChild(board);
      }
    },
    startSnowfall () {
      this.spawnSnowCSS(this.snowFlakesCount);
      this.spawnSnow(this.snowFlakesCount);
    },
    init() {
      this.baseCSS = ``; // Put your custom base css here
      // Load the rules and execute
      this.startSnowfall()
    }
  }
}
</script>

<style lang="scss">
#snowfall {
  position: absolute;
  height: 75vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 5;
  padding-bottom: 35vw;
  overflow: hidden;

  .day-detail-wrapper & {
    height: 100vh;
  }

  @media (min-width: theme("screens.md")) {
    padding-bottom: 200px;
  }
}

.snowflake {
  position: absolute;
  width: 20px;
  height: 23px;
  background-image: url("/images/snowflake-singular.png");
  background-size: cover;
}
// This is weird, but the last two snowflakes were static in the upper left corner.
div.snowflake:nth-last-child(-n+2) {
  display: none;
}
</style>
