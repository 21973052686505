/**
* Check to see if an object has a deep value without throwing an error
* @param {object} object - The object containing the value to check for
* @param {string} path - A string representation of the path to the value. Can use .[index] to indicate array index.
* @param {mixed} defaultValue - A default value that you'd like to return in case an error occurs
* @returns {mixed} - Hopefully this returns the value designated in path. Otherwise it will return the default value.
*/
export function checkDeepValue( object, path, defaultValue = null ) {
    return path.split( '.' )
        .reduce( ( o, p ) => o ? o[ p ] : defaultValue, object )
}

/**
* Prefix a number with zeros
* @param {number} num - The number that should appear after the zeros
* @param {number} [maxZeros] - The optional number of zeros to prefix num, default is 1
* @returns {string} - Num prefixed by 0 one or more times as defined by maxZeros
*/
export const leadZeros = (num, maxZeros = 1) => {
    return '0'.repeat(Math.max(0, maxZeros - (num.toString().length - 1))) + num
}
