<script>
   export default {
      name: 'Vector',
      props: {
         fillHex: {
            type: String,
            default: '#000000'
         }
      }
   }
</script>