<template>
  <!-- eslint-disable -->
  <svg
    width="135"
    height="52"
    viewBox="0 0 135 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M131.312 36.8333C130.697 36.8333 130.236 37.0654 129.775 37.4523C129.391 37.8392 129.161 38.3809 129.161 38.9999C129.161 39.619 129.391 40.0833 129.775 40.5475C130.159 40.9344 130.697 41.1666 131.312 41.1666C131.85 41.1666 132.388 40.9344 132.849 40.5475C133.233 40.1606 133.463 39.619 133.463 38.9999C133.463 38.3809 133.31 37.8392 132.849 37.4523C132.388 37.0654 131.927 36.8333 131.312 36.8333ZM131.312 37.2202C131.773 37.2202 132.234 37.3749 132.541 37.7618C132.849 38.1487 133.079 38.5356 133.079 39.0773C133.079 39.5416 132.926 40.0059 132.541 40.3928C132.234 40.7797 131.85 40.9344 131.312 40.9344C130.851 40.9344 130.467 40.7797 130.083 40.3928C129.775 40.0833 129.545 39.619 129.545 39.0773C129.545 38.5356 129.698 38.1487 130.083 37.7618C130.39 37.3749 130.774 37.2202 131.312 37.2202Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M131.158 37.8394L130.467 37.9167V40.3155H130.851V39.2322H131.081C131.389 39.2322 131.542 39.387 131.619 39.6191C131.619 39.9286 131.696 40.1608 131.773 40.2382H132.157C132.08 40.1608 132.08 39.9286 132.003 39.6191C131.927 39.3096 131.85 39.0774 131.619 39.0774C131.927 39.0001 132.08 38.8453 132.08 38.5358C132.08 38.3036 132.004 38.1489 131.85 37.9941C131.773 37.9167 131.542 37.8394 131.158 37.8394ZM131.158 38.9227H130.851V38.1489H131.158C131.542 38.1489 131.696 38.3036 131.696 38.6132C131.773 38.8453 131.542 38.9227 131.158 38.9227Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M75.606 4.64286C75.606 3.40476 75.145 2.32143 74.2998 1.39286C73.3778 0.464286 72.3021 0 70.9959 0C69.6897 0 68.614 0.464286 67.7688 1.31548C66.8468 2.24405 66.3857 3.32738 66.3857 4.64286C66.3857 5.95833 66.8468 7.04167 67.7688 7.97024C68.614 8.82143 69.6897 9.28571 70.9959 9.28571C72.2252 9.28571 73.3009 8.82143 74.223 7.97024C75.145 6.96429 75.606 5.95833 75.606 4.64286Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M88.4379 4.87503C88.4379 3.55956 87.9769 2.47623 87.1317 1.62503C86.2096 0.696463 85.1339 0.232178 83.9046 0.232178C82.5984 0.232178 81.5227 0.696463 80.6775 1.62503C79.7554 2.47623 79.2944 3.55956 79.2944 4.87503C79.2944 6.11313 79.7554 7.27384 80.6775 8.12503C81.5227 9.05361 82.5984 9.51789 83.9046 9.51789C85.1339 9.51789 86.2096 9.05361 87.1317 8.12503C87.9769 7.19646 88.4379 6.11313 88.4379 4.87503Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M32.8088 12.2262C31.6562 11.5297 30.1195 11.2202 28.1986 11.2202C24.741 11.2202 22.3591 12.7678 21.0529 15.7857C20.3614 12.7678 18.21 11.2202 14.7524 11.2202C11.4485 11.2202 9.06659 12.7678 7.68355 15.7857V11.6845H0V41.1666H8.14456V23.9881C8.14456 21.5119 8.29823 19.7321 8.75925 18.8035C9.14342 17.875 10.0655 17.4881 11.3717 17.4881C12.3705 17.4881 13.062 17.7976 13.3694 18.4166C13.6767 18.9583 13.8304 20.3512 13.8304 22.5178V41.1666H21.975V23.9881C21.975 21.4345 22.1286 19.6547 22.5128 18.7262C22.897 17.875 23.7422 17.4881 25.0484 17.4881C26.1241 17.4881 26.8156 17.7976 27.1229 18.4166C27.4303 19.1131 27.5839 20.5059 27.5839 22.75V41.1666H35.7285V21.8988C35.7285 18.494 35.498 16.1726 35.037 15.0893C34.8065 13.8512 33.9613 12.9226 32.8088 12.2262Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M47.7148 29.4047V27.7797H63.005C63.005 21.3571 61.9293 17.0238 59.6243 14.7023C57.3961 12.3809 54.63 11.1428 51.326 11.1428C47.6379 11.1428 44.7182 12.4583 42.49 15.1666C40.3386 17.875 39.186 21.744 39.186 26.8512C39.186 31.3393 40.1849 34.9762 42.2595 37.6845C44.334 40.3928 47.4074 41.7083 51.326 41.7083C57.7802 41.7083 61.622 38.3035 62.9282 31.4166H55.6288C55.2447 34.4345 53.8616 35.9821 51.5566 35.9821C50.404 35.9821 49.482 35.5952 48.7905 34.6666C48.099 33.7381 47.7148 32.0357 47.7148 29.4047ZM47.7916 22.9821C47.7916 18.7262 49.0978 16.5595 51.5566 16.5595C54.0921 16.5595 55.3215 18.4166 55.3215 22.2083V22.9821H47.7916Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M75.0684 41.1666V11.6844H66.9238V41.1666H75.0684Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M88.4377 11.6844H80.2931V40.0059C80.2931 42.0178 80.1394 43.4106 79.9089 44.0297C79.6016 44.5714 78.8332 44.8809 77.6807 44.8809L76.605 44.8035V51.5356L80.9846 51.9225C82.8287 51.9225 84.4422 51.5356 85.7484 50.6071C86.9778 49.7559 87.7461 48.5178 88.0535 46.9702C88.3608 45.4999 88.5145 43.1785 88.5145 40.1606V11.6844H88.4377Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M95.5066 15.1666C93.3552 17.7976 92.2026 21.6666 92.2026 26.8512C92.2026 31.3393 93.2015 34.9762 95.2761 37.6845C97.3506 40.3928 100.347 41.7083 104.343 41.7083C110.72 41.7083 114.639 38.3035 115.945 31.4166H108.645C108.261 34.4345 106.878 35.9821 104.496 35.9821C103.344 35.9821 102.422 35.5952 101.73 34.6666C101.039 33.7381 100.655 31.9583 100.655 29.4047V27.7797H115.945C115.945 21.3571 114.869 17.0238 112.641 14.7023C110.413 12.3809 107.647 11.1428 104.343 11.1428C100.578 11.1428 97.658 12.5357 95.5066 15.1666ZM108.261 22.2083L108.184 22.9821H100.731C100.731 18.7262 101.961 16.5595 104.496 16.5595C106.955 16.5595 108.261 18.494 108.261 22.2083Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M126.855 11.6845H119.633V41.1667H127.777V28.4762C127.777 22.4405 129.698 19.4226 133.694 19.4226L135 19.5V11.4524L133.617 11.375C130.313 11.375 128.008 13.8512 126.779 18.881V11.6845H126.855Z"
      fill="white"
    />
  </svg>
</template>

<script>
import Vector from "@/mixins/Vector";
export default {
  name: "Logo",
  mixins: [Vector],
  props: {
    branded: Boolean
  }
};
</script>