<template>
  <div class="hero-animation w-full">
    <div
      ref="the"
      class="the hero-animation-text"
    >
      <LazyImage
        img-src="/images/hero-animation/the.png"
        img-src-md="/images/svg/hero-animation/the.svg"
        :screen-width="screenWidth"
        @imgLoaded="theReady = true"
      />
    </div>
    <div
      ref="twelve"
      class="twelve hero-animation-text"
    >
      <LazyImage
        img-src="/images/hero-animation/twelve.png"
        img-src-md="/images/svg/hero-animation/twelve.svg"
        :screen-width="screenWidth"
        @imgLoaded="twelveReady = true"
      />
    </div>
    <div
      ref="days"
      class="days hero-animation-text"
    >
      <LazyImage
        img-src="/images/hero-animation/days.png"
        img-src-md="/images/svg/hero-animation/days.svg"
        :screen-width="screenWidth"
        @imgLoaded="daysReady = true"
      />
    </div>
    <div
      ref="of"
      class="of hero-animation-text"
    >
      <LazyImage
        img-src="/images/hero-animation/of.png"
        img-src-md="/images/svg/hero-animation/of.svg"
        :screen-width="screenWidth"
        @imgLoaded="ofReady = true"
      />
    </div>
    <div
      ref="gifting"
      class="gifting hero-animation-text"
    >
      <LazyImage
        img-src="/images/hero-animation/gifting.png"
        img-src-md="/images/svg/hero-animation/gifting.svg"
        :screen-width="screenWidth"
        @imgLoaded="giftingReady = true"
      />
    </div>
  </div>
</template>

<script>

import { gsap } from "gsap"
import LazyImage from "@/components/LazyImage"

export default {
  name: "HeroAnimation",
  components: { LazyImage },
  props: {
    screenWidth: Number
  },
  data() {
    return {
      tl: null,
      theReady: false,
      twelveReady: false,
      daysReady: false,
      ofReady: false,
      giftingReady: false
    };
  },
  computed: {
    allImagesLoaded () {
      return this.theReady
        && this.twelveReady
        && this.daysReady
        && this.ofReady
        && this.giftingReady
    }
  },
  watch: {
    allImagesLoaded (loaded) {
      if (loaded) {
        this.tl.play()
      }
    }
  },
  mounted () {
    this.setupTl()
    this.$nextTick(() => {
      document.addEventListener('visibilitychange', this.handleVisibilityChange)
    })
  },
  beforeDestroy () {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange)
  },
  methods: {
    setupTl () {
      this.tl = gsap.timeline( { paused: true } )

      // the tweens
      this.tl.fromTo(this.$refs.the,
        {
          opacity: 0,
          x: '5%',
          y: '10%'
        },
        {
          duration: 1,
          ease: 'power1.out',
          opacity: 1,
          x: '0%',
          y: '0%'
        }, 0.5
      );

      // twelve tweens
      this.tl.fromTo(this.$refs.twelve,
        {
          opacity: 0,
          y: '10%'
        },
        {
          duration: 1,
          ease: 'power1.out',
          opacity: 1,
          y: '0%'
        }, 0.75
      );

      // days tweens
      this.tl.fromTo(this.$refs.days,
        {
          opacity: 0,
          x: '-5%',
          y: '10%'
        },
        {
          duration: 1,
          ease: 'power1.out',
          opacity: 1,
          x: '0%',
          y: '0%'
        }, 1
      );

      // of tweens
      this.tl.fromTo(this.$refs.of,
        {
          opacity: 0,
          scale: 1.2
        },
        {
          duration: 1,
          ease: 'power1.out',
          opacity: 1,
          scale: 1,
          transformOrigin: 'center center'
        }, 1
      );

      // gifting tweens
      this.tl.fromTo(this.$refs.gifting,
        {
          opacity: 0,
          scale: 0.8,
          y: '10%'
        },
        {
          duration: 1.1,
          ease: 'back.out(1.4)',
          opacity: 1,
          scale: 1,
          y: '0%'
        }, 1.25
      );
    },
    handleVisibilityChange () {
      // restart the animation if the user switches to a different tab then comes back
      // this prevents the heading from disappearing if the tab becomes inactive for a while
      if (this.tl && !document.hidden){
        this.tl.restart()
      }
    }
  }
};
</script>

<style lang="scss">
  .hero-animation {
    padding-bottom: 65%;
  }

  .hero-animation-text {
    @apply absolute max-w-none opacity-0;

    img {
      display: block;
      width: 100%;
    }

    &.the {
      left: 22.5%;
      top: 20.2%;
      width: 34.8%;
    }

    &.twelve {
      left: 30.6%;
      top: 18.2%;
      width: 40.6%;
    }

    &.days {
      left: 44.9%;
      top: 19.1%;
      width: 36.6%;
    }

    &.of {
      left: 35.8%;
      top: 31.6%;
      width: 32.2%;
    }

    &.gifting {
      left: -2.2%;
      top: 38%;
      width: 105.75%;
    }
  }
</style>
