<template>
  <!-- eslint-disable -->
  <svg
    v-if="direction === 'right'"
    width="10"
    height="16"
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.33106 8.00005L2.31939 0.988385L0.669723 2.63805L6.03639 8.00472L0.669722 13.3714L2.31939 15.0117L9.33106 8.00005Z"
      :fill="fillHex"
    />
  </svg>

  <svg
    v-else
    width="10"
    height="16"
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.669922 7.99995L7.68159 15.0116L9.33125 13.3619L3.96459 7.99528L9.33125 2.62861L7.68159 0.988281L0.669922 7.99995Z"
      :fill="fillHex"
    />
  </svg>
  <!-- eslint-enable -->
</template>

<script>
import Vector from "@/mixins/Vector";
export default {
  name: "Arrow",
  mixins: [Vector],
  props: {
    direction: String
  }
};
</script>
