import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import PrismicVue from '@prismicio/vue'
import LoadScript from 'vue-plugin-load-script'
import VueCookies from 'vue-cookies'
import App from '@/App'
import Home from '@/components/Home'
import DayDetail from '@/components/DayDetail'
import linkResolver from '@/prismic/link-resolver'
import htmlSerializer from '@/prismic/html-serializer'

Vue.config.productionTip = false

const routes = [
   {
      path: '/',
      name: 'home',
      component: Home
   },
   {
      path: '/day/:number',
      name: 'day',
      component: DayDetail
   },
   {
      path: '*',
      name: 'catch-all',
      component: Home
   }
]

const router = new VueRouter({
   routes,
   mode: 'history',
   scrollBehavior (to, from) {
      if (to.name !== from.name) {
         return { x: 0, y: 0 }
      }
   }
})

// activate Vue plugins
Vue.use(VueRouter)
Vue.use(VueMeta)
Vue.use(PrismicVue, {
   endpoint: 'https://meijer-gifting-2022.prismic.io/api/v2',
   linkResolver,
   htmlSerializer
})
Vue.use(LoadScript)
Vue.use(VueCookies)

new Vue({
   components: { App },
   render: h => h(App),
   router
}).$mount('#app')
