<template>
  <div class="calendar relative">
    <transition
      name="fade-up"
      appear
    >
      <div
        v-show="showVillage"
        class="bg absolute w-full top-0 left-0"
      >
        <LazyImage
          class="village"
          img-src="/images/village-sm.png"
          img-src-md="/images/village-md.png"
          img-src-lg="/images/village-lg.png"
          :screen-width="screenWidth"
          @imgLoaded="showVillage = true"
        />
      </div>
    </transition>
    <div class="container relative -mt-16 md:-mt-20 lg:-mt-12 xl:mt-4 mb-8 lg:mb-20">
      <div
        ref="calDays"
        :class="gridWrapClasses"
        class="relative mb-3-5 md:mb-7 lg:mb-4 xl:mb-6"
      >
        <CalendarDay
          v-for="number in dayRange(12, 1)"
          :key="`day-${number}`"
          :number="number"
          :day-data="getDay(number)"
          :days-active="daysActive"
          :animate-intro="animateIntro"
          :show="showCalDays"
          :mobile-day-highlight="mobileDayHighlight"
          :screen-width="screenWidth"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CalendarDay from "@/components/CalendarDay";
import LazyImage from "@/components/LazyImage";
import ScrollTriggers from "@/mixins/ScrollTriggers";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

export default {
  name: "Calendar",
  components: { CalendarDay, LazyImage },
  mixins: [ScrollTriggers],
  props: {
    days: Array,
    daysActive: Number,
    animateIntro: Boolean,
    hoverEnabled: Boolean,
    screenWidth: Number
  },
  data() {
    return {
      showCalDays: false,
      showVillage: false,
      gridWrapClasses:
        "grid grid-cols-2 grid-rows-6 lg:grid-flow-row sm:grid-flow-row sm:grid-cols-2 sm:grid-rows-6 md:grid-cols-3 md:grid-rows-4 xl:grid-cols-4 xl:grid-rows-3",
      mobileDayHighlight: 0
    };
  },
  created() {
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(ScrollToPlugin);
  },
  mounted() {
    this.$nextTick(() => {
      this.setupScrollTriggers();
      if (!this.hoverEnabled) {
        this.setupMobileScrollTriggers();
      }
    });
  },
  beforeDestroy() {
    this.destroyScrollTriggers();
  },

  methods: {
    dayRange(length, start) {
      return Array.from(new Array(length), (value, index) => index + start);
    },
    getDay(number) {
      if (this.days.length < number - 1) return null;
      return this.days[number - 1];
    },
    setupScrollTriggers() {
      this.calDaysTrigger = ScrollTrigger.create({
        trigger: this.$refs.calDays,
        // markers: true,
        start: "top bottom",
        onToggle: self => {
          this.showCalDays = true;
          self.kill();
        }
      });
    },
    getMobileStartTrigger(winHeight) {
      let offset = `top-=${winHeight * .1}`;
      if (this.screenWidth >= 640 && winHeight > 1000) {
        offset = `top+=${winHeight * .15}`;
      }
      return `${offset} center`
    },
    getMobileEndTrigger(winHeight) {
      let offset = `bottom+=${winHeight * .3}`;
      if (this.screenWidth >= 640 && winHeight > 1000) {
        offset = `bottom+=${winHeight * .1}`;
      }
      return `${offset} bottom`
    },
    setupMobileScrollTriggers() {
      const winHeight = window.innerHeight;
      this.calDaysHighlightTrigger = ScrollTrigger.create({
        trigger: this.$refs.calDays,
        // markers: true,
        start: this.getMobileStartTrigger(winHeight),
        end: this.getMobileEndTrigger(winHeight),
        onUpdate: self => {
          this.highlightDaysOnScroll(self);
        }
      });
    },
    highlightDaysOnScroll(scrollTrigger) {
      const dayAmount = 1 / 12;
      const activeDay = scrollTrigger.progress / dayAmount;
      if (activeDay >= 0) {
        this.mobileDayHighlight = Math.ceil(activeDay);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.calendar {
  padding-top: 20px;
}

.container {
  max-width: 1300px;

  @media (min-width: 400px) and (max-width: theme("screens.sm")) {
    margin-top: -18vw;
  }

  @media (min-width: theme("screens.sm")) {
    margin-top: -24vw;
  }

  @media (min-width: theme("screens.md")) {
    margin-top: -8vw;
  }

  @media (min-width: theme("screens.lg")) {
    margin-top: -4vw;
  }

  @media (min-width: theme("screens.xl")) {
    margin-top: 0;
  }

  @media (min-width: 1600px) {
    margin-top: 4vw;
  }

  @media (min-width: 2000px) {
    margin-top: 1vw;
  }
}

.bg {
  img {
    left      : 50%;
    max-width : none;
    position  : absolute;
    width     : 100vw;
  }

  .village {
    transform : translate(-50%, calc(-53vw));

    @media (min-width: theme("screens.sm")) {
      transform : translate(-50%, calc(-40vw));
    }

    @media (min-width: theme("screens.md")) {
      transform : translate(-50%, calc(-5vw - 175px));
    }
  }
}
</style>
