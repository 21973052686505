<template>
  <!-- eslint-disable -->
  <svg
    width="24"
    height="20"
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24 3.0815C23.1177 3.44652 22.1681 3.69234 21.1722 3.80333C22.1889 3.2357 22.9697 2.33732 23.3368 1.26686C22.3865 1.79277 21.3314 2.17344 20.2107 2.37978C19.3123 1.48885 18.0316 0.931641 16.6165 0.931641C13.8975 0.931641 11.6928 2.98466 11.6928 5.51667C11.6928 5.87572 11.7368 6.22584 11.8208 6.5618C7.72829 6.3711 4.10053 4.54528 1.67189 1.77117C1.24792 2.44831 1.00553 3.23571 1.00553 4.07599C1.00553 5.66641 1.87347 7.06985 3.19579 7.89225C2.38784 7.86841 1.62949 7.66207 0.964734 7.31865V7.37601C0.964734 9.59813 2.66302 11.4508 4.91407 11.8731C4.5013 11.9774 4.06613 12.034 3.61736 12.034C3.29978 12.034 2.991 12.005 2.69022 11.9514C3.31658 13.7727 5.13566 15.0987 7.28991 15.1359C5.60523 16.3651 3.48217 17.0988 1.17512 17.0988C0.776747 17.0988 0.384774 17.0772 0 17.034C2.17905 18.3346 4.76688 19.0945 7.5475 19.0945C16.6037 19.0945 21.5562 12.1078 21.5562 6.04929C21.5562 5.85039 21.5522 5.65225 21.5418 5.45633C22.5049 4.80825 23.34 4.00074 24 3.0815Z"
      fill="white"
    />
  </svg>
  <!-- eslint-enable -->
</template>

<script>
import Vector from "@/mixins/Vector";
export default {
  name: "Twitter",
  mixins: [Vector]
};
</script>
