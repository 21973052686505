<template>
  <div
    :style="wrapStyles"
    class="calendar-day flex flex-col z-10 relative mb-16"
    :class="wrapClasses"
    @click="handleClick"
    @mouseenter="hovered = true"
    @mouseleave="hovered = false"
  >
    <transition
      name="fade"
      appear
    >
      <div class="img-wrap absolute top-0 left-0 h-full w-full px-5">
        <svg class="clip-svg absolute h-0 w-0">
          <clipPath
            id="arch-clip-path"
            clipPathUnits="objectBoundingBox"
          >
            <path d="M1,0.359 v0.641 H0 C0,0.786,0,0.573,0,0.359 c0,-0.002,0.001,-0.003,0.001,-0.004 c0.001,-0.01,0.002,-0.02,0.004,-0.031 c0.008,-0.045,0.026,-0.088,0.055,-0.128 c0.04,-0.055,0.095,-0.1,0.165,-0.134 C0.295,0.027,0.372,0.007,0.456,0.002 c0.048,-0.003,0.096,-0.001,0.143,0.006 c0.107,0.017,0.199,0.055,0.272,0.116 c0.075,0.062,0.117,0.135,0.127,0.219 c0.001,0.005,0.001,0.011,0.002,0.016" />
          </clipPath>
        </svg>

        <div class="img-clip h-full w-full bg-cover relative">
          <img
            :src="getBackgroundImage(number)"
            class="absolute h-full w-full object-cover"
          >
          <img
            v-if="dayData"
            :src="dayData.detail_image.url"
            :class="[dayData ? 'activated' : 'deactivated']"
            class="object-cover absolute h-full w-full top-0 right-0"
          >
          <div class="img-overlay absolute h-full w-full opacity-0 transition-opacity duration-300" />
          <transition name="fade-quick">
            <Magic
              v-show="showHighlight"
              class="calendar-magic-wrap top"
            />
          </transition>
        </div>
        <img
          class="arch-border absolute top-0"
          :src="`/images/arch-border.png`"
          alt=""
        >
        <div
          v-if="dayIllustrations.length"
          class="illustrations absolute h-full w-full top-0 left-0 z-10"
        >
          <LazyImage
            v-for="(imgPath, idx) in dayIllustrations"
            :key="`illustration-${number}-${idx}`"
            :class="[
              'illustration absolute',
              `illustration-${idx + 1}`
            ]"
            :img-src="`/images/svg/${imgPath}`"
          />
        </div>
      </div>
    </transition>

    <div class="dateContainer text-center absolute leading-none">
      <span class="date font-meijer-goth-md">{{ date }}</span>
    </div>


    <div class="number-wrap absolute">
      <span class="number block font-playfair italic text-white">{{ calNumber }}</span>
    </div>

    <div
      v-if="title"
      class="titleContainer"
    >
      <span class="title italic font-playfair font-bold text-red-darker text-sm md:text-xl whitespace-no-wrap">{{ title }}</span>
    </div>
  </div>
</template>

<script>
import Magic from "@/components/Magic";
import LazyImage from "@/components/LazyImage";
import { bus } from "@/consts";
import { checkDeepValue, leadZeros } from "@/utils";
import { gtag } from 'ga-gtag';


export default {
  name: "CalendarDay",
  components: { Magic, LazyImage },
  props: {
    number: Number,
    dayData: Object,
    daysActive: Number,
    animateIntro: Boolean,
    show: Boolean,
    mobileDayHighlight: Number,
    screenWidth: Number
  },
  data() {
    return {
      redTile: [1, 8, 9],
      greenTile: [3, 5, 12],
      goldTile: [2, 7, 10],
      brownTile: [4, 6, 11],
      introDelays: [0, 167, 400, 467, 633, 833],
      introDurations: [900, 1033, 867, 833, 900, 900],
      hovered: false,
      imageLoaded: false,
      mobileIllustrations: {
        day1: [],
        day2: ['skier.svg'],
        day3: [],
        day4: [],
        day5: ['mistletoe.svg', 'couple-right.svg'],
        day6: [],
        day7: [],
        day8: [],
        day9: ['decorator-right.svg'],
        day10: [],
        day11: [],
        day12: ['snowman.svg']
      },
      desktopIllustrations: {
        day1: [],
        day2: ['skier.svg'],
        day3: [],
        day4: [],
        day5: [],
        day6: [],
        day7: ['mistletoe.svg', 'couple-left.svg'],
        day8: [],
        day9: ['decorator-left.svg'],
        day10: [],
        day11: [],
        day12: ['snowman.svg']
      }
    }
  },
  computed: {
    calNumber() {
      return `${this.number}`;
    },
    date() {
      return `December ${leadZeros(this.number + 4)}`;
    },
    dateNumeral(){
      return this.number + 4;
    },
    isToday() {
      return process.env.NODE_ENV === "development"
        ? this.daysActive === this.number
        : this.checkIfToday();
    },
    title() {
      return checkDeepValue(this.dayData, "title.0.text", "");
    },
    dayIllustrations() {
      const key = `day${this.number}`
      return this.screenWidth > 1299
        ? this.desktopIllustrations[key]
        : this.mobileIllustrations[key]
    },
    zeroIndex() {
      return parseInt(this.number) - 1;
    },
    wrapClasses() {
      return [
        `day-${this.number}`,
        { today: this.isToday },
        { hovered: this.hovered },
        { 'show-highlight': this.showHighlight },
        this.show ? "opacity-100 show" : "opacity-0",
        this.dayData ? "cursor-pointer" : "cursor-default",
        this.dayData ? "day-active" : "day-inactive"
      ];
    },
    wrapStyles() {
      const cardEase = "cubic-bezier(0,.6,.5,1)";
      const introStyles = {
        transition: `transform ${this.introDuration}ms ${cardEase}, opacity 500ms ease-out`,
        transitionDelay: `${this.introDelay}ms`
      };
      const defaultStyles = {
        transition: `opacity 400ms ease-out`,
        transitionDelay: "0ms"
      };
      return this.animateIntro ? introStyles : defaultStyles;
    },
    introDelay() {
      const delaysLength = this.introDelays.length;
      return this.zeroIndex >= delaysLength
        ? this.introDelays[this.zeroIndex % delaysLength]
        : this.introDelays[this.zeroIndex];
    },
    introDuration() {
      const durationsLength = this.introDurations.length;
      return this.zeroIndex >= durationsLength
        ? this.introDurations[this.zeroIndex % durationsLength]
        : this.introDurations[this.zeroIndex];
    },
    showHighlight() {
      return this.hovered || this.mobileDayHighlight === this.zeroIndex + 1;
    }
  },
  methods: {
    getColorName(number) {
      if (this.redTile.includes(number)) {
        return "red";
      } else if (this.greenTile.includes(number)) {
        return "green";
      } else if (this.goldTile.includes(number)) {
        return "light";
      } else {
        return "brown";
      }
    },
    getTrackingLabel() {
      return `day_${this.dateNumeral - 4}`
    },
    getBackgroundImage(number) {
      return `/images/inactive/inactive-${this.getColorName(number)}.png`;
    },
    handleClick() {
      if (this.dayData) {
        this.sendTracking()
        // fire event that will trigger a page transition animation,
        // followed by navigation to the corresponding detail page
        bus.$emit("goToDetailPage", this.number);
      }
    },
    checkIfToday() {
      if (typeof this.date !== "string" || !this.dayData) return false;
      const dateParts = this.date.split(" ");
      const dateNumber = dateParts[dateParts.length - 1];
      const dateInt = parseInt(dateNumber);
      const today = new Date();
      return dateInt === today.getDate();
    },
    sendTracking() {
      gtag('event', 'link_click', {
        'homepage': `${this.getTrackingLabel()}`, 
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.calendar-day {

  // sets the height of the component with bottom padding
  &:before {
    content: '';
    display: block;
    padding-bottom: 118%;

    @media (min-width: theme("screens.lg")) {
      padding-bottom: 122%;
    }
  }

  &.hovered,
  &.show-highlight {
    .img-overlay {
      opacity: 1;
    }
  }
}

.img-wrap {
  .img-clip {
    clip-path: url(#arch-clip-path);
    left: -10%;
    position: relative;
    top: 2%;
    transform: translateZ(0); // addresses clipPath bug in Safari
    width: 120% !important;

    @media (min-width: 380px) {
      left: -5%;
      width: 110% !important;
    }

    @media (min-width: 500px) {
      left: -3%;
      width: 106% !important;
    }

    @media (min-width: theme("screens.sm")) {
      left: -2%;
      width: 104% !important;
    }

    @media (min-width: theme("screens.lg")) {
      top: 0;
    }
  }
}

.img-overlay {
  background: rgba(0,0,0,0.2);
}

.arch-border {
  height: auto;
  left: 50%;
  max-width : none;
  transform: translatex(-50%);
  width: 130%;
}

.illustration {
  max-width: none;
  pointer-events: none;
}

.day-2 {
  // skier
  .illustration-1 {
    bottom: -21%;
    width: 55%;
    left: -13.5%;

    @media (min-width: theme("screens.xl")) {
      bottom: -17%;
    }
  }
}

.day-5,
.day-7 {
  // mistletoe
  .illustration-1 {
    top: -6.5%;
    left: 24%;
    width: 50%;
  }
}

.day-5 {
  // couple-right
  .illustration-2 {
    bottom: -5%;
    left: -9%;
    width: 30%;
  }
}

.day-7 {
  // couple-left
  .illustration-2 {
    bottom: -16%;
    right: -6%;
    width: 27%;
  }
}

.day-9 {
  // decorator-right
  .illustration-1 {
    bottom: -10%;
    right: -15%;
    width: 114%;

    // decorator-left
    @media (min-width: theme("screens.xl")) {
      bottom: -8%;
      left: -13%;
    }
  }
}

.day-12 {
  // snowman
  .illustration-1 {
    bottom: -18%;
    right: -15%;
    width: 52%;

    @media (min-width: theme("screens.xl")) {
      bottom: -6%;
      right: -21%;
      width: 48%;
    }
  }
}

.day-2, .day-7, .day-10 {

  // Change the numeral / calendar date color on light background
  // Might be an overly simplistic way to accomplish this, but seems like overkill to query data elements / assign dynamic classes?

  &.day-inactive {
    .dateContainer .date {
      color: #7F4B3E;
    }

    .number-wrap .number {
      color: #7F4B3E;
    }
  }
}

.dateContainer {
    right: 50%;
    transform: translateX(50%);
    top: 15%;

  .date {
    color: white;
  }
}

.number-wrap {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%,-50%);

  .number {
    font-size: 85px;
    text-shadow: 0 64.2553px 51.4042px rgba(0, 0, 0, 0.19), 0 26.8443px 21.4755px rgba(0, 0, 0, 0.136582), 0 14.3523px 11.4818px rgba(0, 0, 0, 0.11326), 0 8.04576px 6.43661px rgba(0, 0, 0, 0.095), 0 4.27304px 3.41843px rgba(0, 0, 0, 0.0767396), 0 1.77811px 1.42249px rgba(0, 0, 0, 0.0534177);
    transform: translateY(5%);

    @media (min-width: 369px) {
      font-size: 110px;
      transform: none;
    }

    @media (min-width: 500px) {
      font-size: 150px;
    }

    @media (min-width: theme("screens.md")) {
      font-size: 185px;
    }
  }
}

.titleContainer {
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
}

.title {
  padding : 8px 18px 10px;
  background: white;
  border-radius: 40px;

  @media (min-width: theme("screens.md")) {
    padding: 10px 28px 15px;
  }

  @media (max-width: 340px) {
    font-size: 11px;
    padding: 6px 14px 8px;
  }
}

.calendar-magic-wrap {
  &.top {
    right: 0;
    top: 0;
    transform: translate(45%, 0) rotate(90deg);
  }
}
</style>
