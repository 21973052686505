<script>
   import gsap from 'gsap'
   import { ScrollTrigger } from 'gsap/ScrollTrigger'

   export default {
      name: 'ScrollTriggers',
      created () {
         gsap.registerPlugin(ScrollTrigger)
      },
      methods: {
         refreshScrollTriggers () {
            const triggers = ScrollTrigger.getAll()
            triggers.forEach( trigger => {
               trigger.refresh()
            })
         },
         destroyScrollTriggers () {
            const triggers = ScrollTrigger.getAll()
            triggers.forEach( trigger => {
              trigger.kill()
            })
         }
      }
   }
</script>