<template>
    <!-- eslint-disable -->
    <svg
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.3134 0.897044L0.886583 6.66005C0.621339 6.86661 0.478516 7.1558 0.478516 7.48629V17.4631C0.478516 18.0415 0.947793 18.5166 1.51909 18.5166H7.1096V13.5591C7.1096 12.5677 7.94614 11.7621 8.94591 11.7621C9.94567 11.7621 10.7822 12.5677 10.7822 13.5591V18.5166H16.3727C16.944 18.5166 17.4133 18.0415 17.4133 17.4631V7.48629C17.4133 7.1558 17.2705 6.84596 17.0052 6.66005L9.57841 0.897044C9.21115 0.628516 8.68066 0.628516 8.3134 0.897044Z"
            fill="#AB2522"
        />
    </svg>

    <!-- eslint-enable -->
</template>

<script>
import Vector from "@/mixins/Vector";

export default {
    name: "Home",
    mixins: [Vector],
};
</script>